import tagManager from "./tags";
import posthog, { PostHog } from "posthog-js";


function afterPostHogLoaded(posthog: PostHog) {
  const distinctId = posthog.get_distinct_id(); // relevant if .identify() was called
  const sessionId = posthog.get_session_id();
  tagManager.setTags({
    "chat:posthog.distinctId": distinctId,
    "chat:posthog.sessionId": sessionId,
  });
}

export function setupAnalytics() {
  if (!import.meta.env.VITE_POSTHOG_APIKEY) {
    console.log("botbrains.analytics.init: skipping for 'local' environment");
    return;
  }
  console.log("botbrains.analytics.init");
  posthog.init(import.meta.env.VITE_POSTHOG_APIKEY, {
    disable_persistence: true,
    person_profiles: "identified_only", // reduces costs by 80%, because we do not process person profiles
    api_host: "https://eu.posthog.com",
    loaded: afterPostHogLoaded,
    disable_session_recording: true,
  });
}
